const Cakes = Object.freeze({
    RAFAELLO: "RAFAELLO",
    CORONA: "CORONA",
    NAPOLEON_WITHOUT_DECORATION: "NAPOLEON_WITHOUT_DECORATION",
    CUSTOMIZED_BDAY_CAKE: "CUSTOMIZED_BDAY_CAKE",
    HONEY_CAKE: "HONEY_CAKE",
    NAPOLEON_PLAIN: "NAPOLEON_PLAIN",
    FRUIT_CAKE: "FRUIT_CAKE",
    SAMAR_CAKE: "SAMAR_CAKE",
    BDAY_FRUIT_CAKE: "BDAY_FRUIT_CAKE",
    ROSE_CAKE: "ROSE_CAKE",
    NUMBERS_BDAY_CAKE: "NUMBERS_BDAY_CAKE",
    NAPOLEON_WITH_BERRIES: "NAPOLEON_WITH_BERRIES",
    BENTO_CAKE: "BENTO_CAKE",
    BLACK_CAKE: "BLACK_CAKE",
    BABY_SHOWER_CAKE: "BABY_SHOWER_CAKE",
    WITH_NAME_CAKE: "WITH_NAME_CAKE",
    CINNAMOROLL_CAKE: "CINNAMOROLL_CAKE",
});

export { Cakes }